<template>
  <div id="info-card" v-html="markdown(info)"></div>
</template>

<script>
import showdown from 'showdown'
const converter = new showdown.Converter()
export default {
  name: 'InfoCard',
  props: {
    info: { type: String }
  },
  methods: {
    markdown: text => converter.makeHtml(text)
  }
}
</script>

<style>
#info-card {
  background: white;
  border-radius: 10px;
  padding: 10px 25px;
  margin: 10px;
  text-align: left;
  width: 500px;
  border-bottom: 10px solid #009645;
  display: flex;
  flex-flow: column;
  line-height: 20px;
  color: #330D73;
  box-shadow: 0px, 0px, 10px, rgba(0, 0, 100, 0.5); }
  #info-card li {
    line-height: 24px;
    display: block;
    text-align: left; }
  #info-card h1, #info-card h2, #info-card h3, #info-card h4, #info-card h5 {
    text-align: center;
    margin: 10px; }

    @media screen and (max-width: 1024px) {
      #info-card {
        width: auto;
      }
    }
</style>