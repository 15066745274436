<template>
  <div id="publications">
    <div v-if="hasData(publicationpage)" class="content">
      <h2 class="page-title">{{publicationpage.data.attributes.title}}</h2>
      <div class="publications">
        <a class="publication" target="__blank" v-for="(publication, key) in publicationpage.data.attributes.publications" :href="publication.url" :key="key">
          <img :src="Url(publication.thumbnail.data.attributes.url)" width="120" height="170" alt="">
          <h3>{{publication.title}}</h3>
        </a>
      </div>
      <h2 v-if="publicationpage.data.attributes.secondtitle" class="page-title">{{publicationpage.data.attributes.secondtitle}}</h2>
      <Downloads v-if="publicationpage.data.attributes.downloads.length > 0" :downloads="publicationpage.data.attributes.downloads" :newsletter="true" />
    </div>
  </div>
</template>

<script>
import Downloads from '@/components/Downloads'
import showdown from 'showdown'
import { mapGetters } from 'vuex'
import { publicationpage } from '../queries'
const converter = new showdown.Converter()

export default {
  name: 'Publications',
  methods: {
    markdown: text => converter.makeHtml(text),
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  },
  apollo: {
    publicationpage
  },
  computed: {
    ...mapGetters([
      'Url'
    ])
  },
  components: {
    Downloads
  }
}
</script>

<style>
#publications .content {
  margin-top: 20px;
  color: #330D73; }
  #publications .content .page-title {
    color: white;
    padding: 10px;
    background: #009645; }
  #publications .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #publications .content .publications {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap; }
    #publications .content .publications .publication {
      text-decoration: none;
      padding: 10px; }
      #publications .content .publications .publication:hover {
        transform: scale(1.1) !important; transition: all 0.3s; }
      #publications .content .publications .publication img {
        border-radius: 4px;
        box-shadow: 0px 0px 35px #130036; }
      #publications .content .publications .publication h3 {
        color: white;
        text-shadow: 1px 1px 1px #130036;
        text-decoration: none; }
@media screen and (max-width: 1024px) {
  #publications .content h2 {
    margin-top: 0;
  }
}
</style>
