<template>
  <div id="virtual-tour">
    <div v-if="hasData(opendaypage)" class="content">
      <h2 class="page-title">{{opendaypage.data.attributes.title}}</h2>
      <About :message="opendaypage.data.attributes.about" />
      <h3 class="video-heading">Videos <br><small style="font-weight: 100">Please click on the links below to view our school videos.</small></h3>
      <div class="videos">
        <VideoCard v-for="(video, key) in opendaypage.data.attributes.videos" :key="key" :video="video" />
      </div>
      <About :message="opendaypage.data.attributes.important" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About'
import VideoCard from '@/components/VideoCard'
import showdown from 'showdown'
import { opendaypage } from '../queries'

const converter = new showdown.Converter()
export default {
  name: 'OpenDay',
  apollo: { opendaypage },
  methods: {
    markdown: text => converter.makeHtml(text),
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  },
  components: {
    About,
    VideoCard
  }
}
</script>

<style>
#virtual-tour .content {
  margin-top: 20px;
  color: #330D73; }
  #virtual-tour .content .page-title {
    color: white;
    padding: 10px;
    background: #009645; }
  #virtual-tour .content .videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap; }
  #virtual-tour .content .downloads h4 {
    font-weight: 100;
    font-size: 18px;
    color: white;
    border-bottom: 1px solid #330D73; }
  #virtual-tour .content .links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column; }
    #virtual-tour .content .links a {
      padding: 10px;
      background: linear-gradient(90deg, #330D73, #6B3EB8);
      width: 400px;
      margin: 5px;
      color: white; }
      @media screen and (max-width: 1024px) {
        #virtual-tour .content .links a {
          width: 100%; } }
      #virtual-tour .content .links a:hover {
        background: #330D73; }
      #virtual-tour .content .links a i {
        color: white;
        float: right;
        margin-left: 10px; }
  #virtual-tour .content .video-heading {
    margin: 5px;
    background: #009645;
    color: white;
    margin-left: 20%;
    width: 60%;
    padding: 10px; }
    @media screen and (max-width: 1024px) {
      #virtual-tour .content h2 {
        margin-top: 0;
      }
      #virtual-tour .content .video-heading {
        margin-left: 0;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      } }
</style>


