<template>
  <div id="enrichment-centre">
    <div v-if="hasData(enrichmentpage)" class="content">
      <h2>{{enrichmentpage.data.attributes.title}}</h2>
      <About :message="enrichmentpage.data.attributes.about" />
      <About :message="enrichmentpage.data.attributes.history" />
      <div class="info">
        <InfoCard v-for="(info, key) in enrichmentpage.data.attributes.information" :key="key" :info="info.info" />
      </div>
      <h3 class="hours-heading">School Hours</h3>
      <div class="hours">
        <TimeCard v-for="(grade, key) in enrichmentpage.data.attributes.hours" :key="key" :title="grade.title" :times="grade.times" />
      </div>
      <Staff title="Meet our staff" :staff="enrichmentpage.data.attributes.staff" />
      <Downloads :downloads="enrichmentpage.data.attributes.downloads" />
      <div id="support-links">
        <h3>Support</h3><br>
        <InfoBox title="Get Involved" :info="enrichmentpage.data.attributes.getinvolved" />
        <a
          class="info-box-link"
          target="_blank"
          href="https://www.facebook.com/Enrichment-Centre-Pathways-Polokwane-193541731102131">
          Facebook<fa-icon class="icon" icon="fa-brands fa-facebook" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo/logo'
import Navbar from '@/components/Navbar'
import About from '@/components/About'
import InfoCard from '@/components/InfoCard'
import Staff from '@/components/Staff'
import TimeCard from '@/components/TimeCard'
import Downloads from '@/components/Downloads'
import InfoBox from '@/components/InfoBox'
import Footer from '@/components/Footer'
import { enrichmentpage } from '../queries'
export default {
  name: 'EnrichmentCentre',
  apollo: { enrichmentpage },
  components: {
    About,
    Staff,
    TimeCard,
    Downloads,
    InfoCard,
    InfoBox,
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#enrichment-centre .info-box-link {
  background: white;
  padding: 10px;
  color: #330D73;
  display: flex;
  justify-content: space-between;
  width: 300px;
  text-decoration: none; }
  #enrichment-centre .info-box-link:hover {
    background: #330D73;
    color: white;
    cursor: pointer; }
  #enrichment-centre .info-box-link .icon {
    color: #330D73; }

#enrichment-centre #support-links {
  background: linear-gradient(#00cc66, #009645);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center; }

#enrichment-centre .content {
  margin-top: 20px; }
  #enrichment-centre .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #enrichment-centre .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #enrichment-centre .content .hours-heading {
    padding: 5px;
    color: white;
    background: #009645; }
  #enrichment-centre .content .hours {
    display: flex;
    justify-content: center;
    flex-flow: wrap; }
    @media screen and (max-width: 1024px) {
      #enrichment-centre .content h2 {
        margin-top: 0;
      }
    }
</style>
