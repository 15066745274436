<template>
  <div id="sport">
    <div v-if="hasData(sportpage)" class="content">
      <h2 class="page-title">{{sportpage.data.attributes.title}}</h2>
      <About :message="sportpage.data.attributes.about" />
      <div class="inner-container">
        <div class="sports">
          <h3 class="sports-title">Sports</h3>
          <div class="sports-cards">
            <SportCard v-for="(sport, key) in sportpage.data.attributes.sports" :key="key" :title="key" :sports="sport" />
          </div>
        </div>
        <div class="times">
          <h4 class="times-title">Times</h4>
          <div class="times-cards">
            <TimeCard v-for="(group, key) in sportpage.data.attributes.hours" :key="key" :title="group.title" :times="group.times"/>
          </div>
        </div>
      </div>
      <Staff title="Meet the staff" :staff="sportpage.data.attributes.staff"/>
    </div>
  </div>
</template>

<script>
import SportCard from '@/components/SportCard'
import TimeCard from '@/components/TimeCard'
import Staff from '@/components/Staff'
import About from '@/components/About'
import { sportpage } from '../queries'

export default {
  name: 'Sport',
  apollo: {
    sportpage
  },
  components: {
    SportCard,
    TimeCard,
    Staff,
    About
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#sport .content {
  margin-top: 20px; }
  #sport .content .page-title {
    color: white;
    padding: 10px;
    background: #009645; }
  #sport .content .about {
    background: white;
    text-align: left;
    color: #330D73;
    padding: 20px;
    line-height: 24px;
    margin: 20px;
    width: 70%;
    margin-left: 15%;
    font-family: "Segoe UI",Arial,sans-serif;
    box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 480px) {
      #sport .content .about {
        width: 100%;
        margin-left: 0; } }
    #sport .content .about h3 {
      text-align: center; }
  #sport .content .inner-container {
    background: linear-gradient(#fff, #e1e1e1);
    background: linear-gradient(#6B3EB8, #330D73);
    opacity: 0.9; }
    #sport .content .inner-container .sports {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap column; }
    #sport .content .inner-container .times-title, #sport .content .inner-container .sports-title {
      color: white;
      background: #009645;
      padding: 10px; }
    #sport .content .inner-container .times {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 20px; }
      #sport .content .inner-container .times .times-cards {
        display: flex;
        justify-content: center;
        flex-flow: wrap row; }
@media screen and (max-width: 1024px) {
  #sport .content {
    margin-top: 0px;
  }
  #sport .content .inner-container .times {
    padding: 0px;
  }
  .sports-cards {
    width: auto;
    padding: 0;
    margin: 0;
  }
}
</style>
