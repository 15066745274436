<template>
  <div v-if="downloads" class="downloads" :class="{ 'newsletter': newsletter }">
    <div class="download-section" v-for="(section, key) in downloads" :key="key">
      <h3>{{section.title}}</h3>
      <div v-for="(download, key) in section.downloads" :key="key">
        <a target="_blank" :href="Url(download.file.data.attributes.url)">
          <div class="file">{{download.title}}<fa-icon icon="fa-solid fa-file-arrow-down" /></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Downloads',
  props: {
    downloads: Array,
    title: String,
    newsletter: Boolean
  },
  computed: {
    ...mapGetters([
      'Url'
    ])
  }
}
</script>

<style>
.downloads {
  background: linear-gradient(#00cc66, #009645);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  flex-flow: row;}
  .downloads h3 {
    margin-bottom: 10px; }
  .downloads .file {
    padding: 10px;
    background: linear-gradient(to left, #6B3EB8, #330D73);
    color: white;
    width: 300px;
    font-weight: 600;
    margin: 5px;
    display: flex;
    justify-content: space-between; }
    .downloads .file .icon {
      color: white; }
  .downloads a {
    text-decoration: none; }
    .downloads a:hover > .file {
      background: #330D73;
      transition: all 0.3s;
      color: white; }
.downloads.newsletter {
  background: #ffffff48;
  border-top: 1px solid #fff;
  margin-top: -20px;
  border-bottom: 10px solid #330D73;
  padding-bottom: 3em;
  padding-top: 0px;
  color: #ffffff;
  text-shadow: 1px 1px 1px #868686;
}

.downloads.newsletter .file {
  background: #fff;
  color: #330D73;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5em;
  font-size: 1.2em;
  align-items: center;
  box-shadow: 0px 0px 20px #320d7363;
}
@media screen and (max-width: 1024px) {
  .downloads {
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
    flex-flow: wrap;
  }
}
</style>
