<template>
  <div id="college">
    <div v-if="hasData(collegepage)" class="content">
      <h2>{{collegepage.data.attributes.title}}</h2>
      <div class="link-cards">
        <a
          class="link" 
          v-for="(linkcard, index) in collegepage.data.attributes.linkcards"
          :key="index"
          :href=linkcard.url
        >
          <div>
            {{linkcard.title}}
          </div>
        </a>
      </div>
      <About :message="collegepage.data.attributes.about" />
      <div class="info">
        <InfoCard v-for="(info, key) in collegepage.data.attributes.information" :key="key" :info="info.info" />
      </div>
      <h3 class="hours-heading">School Hours</h3>
      <div class="hours">
        <TimeCard v-for="(grade, key) in collegepage.data.attributes.hours" :key="key" :title="grade.title" :times="grade.times" />
      </div>
      <Staff title="Meet our staff" :staff="collegepage.data.attributes.staff" />
      <Downloads :downloads="collegepage.data.attributes.downloads" />
    </div>
  </div>
</template>

<script>
import LinkCard from '@/components/LinkCard'
import About from '@/components/About'
import InfoCard from '@/components/InfoCard'
import Staff from '@/components/Staff'
import TimeCard from '@/components/TimeCard'
import Downloads from '@/components/Downloads'
import { collegepage } from '../queries'
export default {
  name: 'College',
  apollo: { collegepage },
  components: {
    LinkCard,
    About,
    Staff,
    TimeCard,
    Downloads,
    InfoCard
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#college .content {
  margin-top: 20px; }
  #college .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #college .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #college .content .hours-heading {
    padding: 5px;
    color: white;
    background: #009645; }
  #college .content .hours {
    display: flex;
    justify-content: center;
    flex-flow: wrap; }
    #college .link-cards {
    display: flex;
    flex-flow: wrap;
    width: 80%;
    margin-left: 10%;
  }
  #college .link {
    background: #fff;
    padding: 1em;
  }
  #college .link-cards a {
    color: #009645;
    font-weight: 600;
    text-decoration: none;
  }
  #college .link:hover {
    color: #fff;
    cursor: pointer;
    background: #330D73;
    text-decoration: none;
  }
  @media screen and (max-width: 1024px) {
    #college .content h2 {
      margin-top: 0;
    }

  }
</style>
