<template>
  <div id="contact-us">
    <div class="content">
      <h2>Contact us</h2>
      <div class="contact-details">
        <div class="info">
          <h3>Contact details</h3>
          <b>We are located at</b>
          <span><fa-icon class="icon" icon="fas fa-map-marker-alt" />Plot 153, Twee Fontein, Polokwane, 0699</span>
          <span><a target="_blank" href="https://www.google.com/maps/search/?api=1&query=-23.8687505,29.5078112"><fa-icon class="icon" icon="fas fa-map-location-dot" />-23.8687505, 29.5078112</a></span>
          <br>
          <b>Telephone</b>
          <span><fa-icon class="icon" icon="fas fa-phone" /> 015 296 0865</span>
          <br>
          <b>Email</b>
          <span><fa-icon class="icon" icon="fas fa-envelope" />reception@mitchellhouse.co.za</span>
          <span><fa-icon class="icon" icon="fas fa-envelope" />marketing@mitchellhouse.co.za</span>
        </div>
        <form v-if="showForm && !mailSent" class="mail">
          <h4>Request more information or just say hi :).</h4>
          <input :class="{ success: data.valid.name }" v-model="data.name" type="text" name="name" id="name" placeholder="Names" @input="verifyInput" required>
          <input :class="{ success: data.valid.email }" v-model="data.email" type="email" name="email" id="email" placeholder="Email" @input="verifyInput" required>
          <input :class="{ success: data.valid.number }" v-model="data.number" type="tel" name="number" id="number" placeholder="Contact Number" @input="verifyInput" maxlength="10" required>
          <input :class="{ success: data.valid.heard }" v-model="data.heard" type="text" name="heard" id="heard" placeholder="Where did you hear of us" @input="verifyInput" required>
          <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" :value="data.token" />
          <textarea :class="{ success: data.valid.message }" v-model="data.message" name="message" id="message" cols="30" rows="10" placeholder="Your message.." @input="verifyInput" required></textarea>
          <input type="submit" @click="sendMail" value="send" :disabled="!data.isValid">
        </form>
        <SmallLoader v-else-if="!showForm && !mailSent && !sending" text="Finding a recaptcha token. :)" />
        <SmallLoader v-else-if="!showForm && !mailSent && sending" text="Sending your message please wait. :D" />
        <div class="sent" v-else>Thank you for contacting us, we will get back to you as soon as possible.</div>
      </div>
      <div id="map" @load="loadMap">
        <SmallLoader text="Loading map..." />
      </div>
    </div>
  </div>
</template>

<script>
import SmallLoader from '@/components/SmallLoader'
import axios from 'axios'
import { load } from 'recaptcha-v3'
export default {
  name: 'Contactus',
  data () {
    return {
      data: {
        name: '',
        email: '',
        number: '',
        token: '',
        heard: '',
        message: '',
        isValid: false,
        valid: {name: false, email: false, number: false, heard: false, message: false}
      },
      showForm: false,
      mailSent: false,
      sending: false
    }
  },
  mounted () {
    this.$store.dispatch('showLoader', false)
    this.loadRecaptcha()
    this.loadMap()
  },
  methods: {
    sendMail (event) {
      event.preventDefault()
      if (this.data.isValid) {
        this.showForm = false
        this.sending = true
        const data = {
          to: 'reception@mitchellhouse.co.za',
          from: 'website@mitchellhouse.co.za',
          subject: 'Mitchell House Website',
          html: `
            <h3>A message from mitchellhouse.co.za</h3>
            name: <b>${this.data.name}</b><br>
            email: <b>${this.data.email}</b><br>
            number: <b>${this.data.number}</b><br>
            heard of us from: <b>${this.data.heard}</b><br>
            message: <b>${this.data.message}</b>
          `
        }
        axios.post('https://mitchellhouse.herokuapp.com/email', data)
        .then(response => {
          this.mailSent = true
          this.sending = false
        })
        .catch(error => {})
      }
    },
    verifyInput (event) {
      const { name, email, number, heard, message, token } = this.data
      this.data.valid.name = name.length >= 3
      this.data.valid.email = /\S+@\S+\.\S+/.test(email)
      this.data.valid.number = this.verifyCell(number)
      this.data.valid.heard = heard.length >= 3
      this.data.valid.message = message.length >= 10
      if (
        this.data.valid.name &&
        this.data.valid.email &&
        this.data.valid.number &&
        this.data.valid.heard &&
        this.data.valid.message
      ) {
        this.data.isValid = true
      } else {
        this.data.isValid = false
      }
    },
    verifyCell (cell) {
      if (cell) {
        if (cell.length === 10) {
          for (let char in cell) {
            const isNumber = Number.isInteger(parseInt(cell[char]))
            if (!isNumber) {
              return false
            }
          }
        } else {
          return false
        }
        return true
      } else return false
    },
    async loadMap () {
      const position = {lat: -23.8687505, lng: 29.5078112}
      const map = await new google.maps.Map(document.getElementById('map'), {
        center: position,
        zoom: 16
      });
      const marker = new google.maps.Marker({ position, map, title: 'Mitchell House' })
    },
    loadRecaptcha () {
      const siteKey = this.$store.getters.ReSiteKey
      load(siteKey, {
        useRecaptchaNet: true,
        autoHideBadge: true
      }).then(recaptcha => {
        recaptcha.execute('/').then(reToken => {
          this.data.token = reToken
          this.showForm = true
        }).catch(error => {
        })
      }).catch(error => {
      })
    }
  },
  components: {
    SmallLoader
  }
}
</script>

<style>
#contact-us .sent {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  font-size: 24px;
  padding: 10px;
  color: #330D73;
  font-weight: 600; }

#contact-us .success {
  background: #ccffcc !important; }

#contact-us .content {
  margin-top: 20px; }
  #contact-us .content .icon {
    color: #39C479;
    margin-right: 10px;
    margin-top: 5px;
    font-size: 18px; }
  #contact-us .content .contact-details {
    min-height: 550px;
    display: flex;
    justify-content: center;
    flex-flow: wrap; }
    #contact-us .content .contact-details .info span {
      display: flex;
    }
    #contact-us .content .contact-details .info span a {
      color: white;
    }
    @media screen and (max-width: 480px) {
      #contact-us .content .contact-details {
        margin: 0; } }
    #contact-us .content .contact-details b {
      color: #39C479; }
    #contact-us .content .contact-details .info {
      background: linear-gradient(#6B3EB8, #330D73);
      opacity: 0.9;
      color: white;
      text-align: left;
      padding: 20px 20px;
      display: flex;
      justify-content: space-evenly;
      flex-flow: column;
      box-shadow: 0px, 8px, 15px, rgba(0, 0, 100, 0.5); }
      @media screen and (max-width: 480px) {
        #contact-us .content .contact-details .info {
          box-shadow: none; } }
      #contact-us .content .contact-details .info h3 {
        text-align: center; }
    #contact-us .content .contact-details .mail {
      color: #330D73;
      max-width: 400px;
      background: white;
      display: flex;
      justify-content: center;
      padding: 20px 10px;
      flex-flow: wrap row; }
      #contact-us .content .contact-details .mail input, #contact-us .content .contact-details .mail textarea {
        font-size: 14px;
        background: #e1e1e1;
        border: 1px solid #c2c1ef;
        border-radius: 5px;
        padding: 6px;
        margin-top: 7px;
        width: 300px; }
        #contact-us .content .contact-details .mail input:focus, #contact-us .content .contact-details .mail textarea:focus {
          outline: none;
          border: 1px solid #330D73; }
      #contact-us .content .contact-details .mail input[type=submit] {
        background: #330D73;
        color: white; }
        #contact-us .content .contact-details .mail input[type=submit]:disabled {
          background: #d1d1ef; }
        #contact-us .content .contact-details .mail input[type=submit]:hover {
          cursor: pointer;
          background: #009645; }
  #contact-us .content #map {
    width: 100%;
    height: 400px;
    border-top: 10px #009645 solid;
    border-bottom: 10px #009645 solid;
    background: #f2f1f0;
    color: #330D73;
    display: flex;
    align-items: center;
    justify-content: center; }
  #contact-us .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
@media screen and (max-width: 1024px) {
  #contact-us .content h2 {
    margin-top: 0;
  }
}
</style>