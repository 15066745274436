<template>
  <div v-if="hasData(admissionpage)" id="admissions">
    <h2>{{admissionpage.data.attributes.title}}</h2>
    <div id="content" class="content">
      <Contents :contents="admissionpage.data.attributes.contents" />
      <About :message="admissionpage.data.attributes.introduction" />
      <About :message="admissionpage.data.attributes.values" />
      <About :message="admissionpage.data.attributes.approach" />
      <About :message="admissionpage.data.attributes.process" />
      <Downloads :downloads="admissionpage.data.attributes.downloads" />
      <button id="up-button" @click="scrollup">
        <fa-icon class="icon" icon="fas fa-arrow-up" />
      </button>
    </div>
  </div>
</template>

<script>
import Contents from '@/components/Contents'
import About from '@/components/About'
import InfoBox from '@/components/InfoBox'
import Downloads from '@/components/Downloads'
import { admissionpage } from '../queries'

export default {
  name: 'Sport',
  apollo: { admissionpage },
  mounted () {
    document.addEventListener('scroll', () => {
      const contents = document.getElementById('contents')
      if (contents) {
        if (window.innerWidth > 1024) {
          if (window.scrollY < 300) {
            contents.style.marginTop = -window.scrollY - 30 + 'px'
          } else {
            contents.style.marginTop = -280 + 'px'
          }
        } else {
          contents.style.marginTop = '-20px'
        }
      }
    })
  },
  methods: {
    scrollup () {
      const content = document.querySelector('body')
      content.scrollIntoView(true)
    },
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  },
  components: {
    Contents,
    About,
    InfoBox,
    Downloads
  }
}
</script>

<style>
#admissions #support-links {
  background: linear-gradient(#00cc66, #009645);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center; }
#admissions .about {
  margin-left: 400px;
}
#admissions #up-button {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #330D73;
  color: white;
  border: none;
  border-radius: 5px;
}
#admissions #up-button:hover {
  transform: scale(1.1);
  transition: 0.3s all;
  cursor: pointer;
}
#admissions .content {
  margin-top: 20px;
  color: #330D73; }
  #admissions h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #admissions .content h3 {
    text-align: center; font-size: 24px; font-weight: 600; }
  #admissions .about strong {
    font-size: 20px !important;
  }
  #admissions a {
    color: #009645;
  }
  #admissions .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #admissions .content .headmaster-message {
    height: auto;
    padding: 20px;
    margin: 10px;
    background: white;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-left: 10%;
    max-width: 80%;
    box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2); }
    
    @media screen and (max-width: 1024px) {
      #admissions .content {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
      }
      #admissions .content .headmaster-message {
        flex-flow: wrap;
        max-width: 100%;
        margin: 0;
        margin-bottom: 10px; }
      #admissions #contents {
        position: relative;
      }

      #admissions .about {
        position: relative;
        margin-left: 0;
        width: 100%;
      }
    }

    #admissions .content .headmaster-message .headmaster-img {
      width: 300px; }
      @media screen and (max-width: 1024px) {
        #admissions .content .headmaster-message .headmaster-img {
          width: 200px; } }
    #admissions .content .headmaster-message .message {
      text-align: justify;
      padding: 10px; }
</style>
