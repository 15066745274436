import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// const serverUrl = 'http://localhost:1338'
const serverUrl = 'https://api.mitchellhouse.co.za'
export default new Vuex.Store({
  state: {
    showLoader: true,
    reSiteKey: '6Ld6G74UAAAAAG_KP8Y1NGPtPv0R3QatrXQNcoiS'
  },
  getters: {
    GetUrl: (state) => (url) => { return state.url[url] },
    showLoader: (state) => { return state.showLoader },
    ReSiteKey: (state) => { return state.reSiteKey },
    Url: (state) => url => { return serverUrl + url },
    GetServer: () => serverUrl
  },
  mutations: {
    showLoader (state, show) {
      state.showLoader = show
    }
  },
  actions: {
    showLoader (context, show) {
      context.commit('showLoader', show)
    },
    sendMail (context, data) {
      context.commit('sendMail', data)
    }
  }
})
