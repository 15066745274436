<template>
  <div class="vacancy-card fade-in-bwd">
    <div>
      <h3>{{data.position}}</h3><br><br>
      <div class="description" v-html="markdown(data.description)"></div>
      <br>
    </div>
    <div class="times">
      <small>Expires: <b>{{date(data.expiry)}}</b></small>
    </div>
    <div class="buttons">
      <a v-if="data.advert" :href="Url(data.advert.data.attributes.url)" target="_blank">Advert</a>
      <a v-if="data.specifications" :href="Url(data.specifications.data.attributes.url)" target="_blank">Specifications</a>
    </div>
  </div>
</template>

<script>
import showdown from 'showdown'
import { mapGetters } from 'vuex'
const converter = new showdown.Converter()
export default {
  name: 'VacancyCard',
  props: {
    data: { type: Object }
  },
  methods: {
    markdown: text => converter.makeHtml(text),
    date: date => date.substring(0, date.indexOf('T'))
  },
  computed: {
    ...mapGetters([
      'Url'
    ])
  }
}
</script>

<style>
.vacancy-card {
  width: 400px;
  min-height: 400px;
  background: white;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  overflow: hidden;
  word-wrap: break-word;
  animation-delay: 0.2s !important;
  animation-duration: 0.5s !important;
  color: #330D73;
  border: 5px solid white;
  border-bottom: 5px solid #330D73;
  border-top: 1px solid #009645;
  box-shadow: 0px, 0px, 5px, rgba(0, 0, 100, 0.2);
  text-align: center; }
  .vacancy-card .times {
    text-align: center;
    width: 100%; }
  .vacancy-card h4 {
    max-width: 280px; }
  .vacancy-card .description {
    max-width: 280px; }
  .vacancy-card .buttons {
    display: flex;
    justify-content: center; }
  .vacancy-card .buttons a {
    margin: 5px;
    text-decoration: none;
    background: #009645;
    width: 125px;
    height: 30px;
    color: white;
    padding: 5px;
    border-radius: 5px; }
    .vacancy-card .buttons a:hover {
      background: #19B25F;
      transition: 0.3s; }
</style>
