<template>
  <div id="vacancies">
    <div v-if="hasData(vacancypage)" class="content">
      <h2>Vacancies</h2>
      <br>
      <div id="vacancy-cards">
        <div v-if="anyVacancies" class="vacancies">
          <VacancyCard
            v-for="(vacancy, key) in vacancypage.data.attributes.vacancies"
            :key="key"
            :data="vacancy"
          />
        </div>
        <h3 v-else >There are currently no available Vacancies. Please come back later :)</h3>
      </div>
    </div>
    <div v-else>
      <h3>There are currently no vacancies open.</h3>
    </div>
  </div>
</template>

<script>
import VacancyCard from '@/components/VacancyCard'
import { vacancypage } from '../queries'
export default {
  name: 'Vacancies',
  apollo: { vacancypage },
  computed: {
    anyVacancies () {
      return this.vacancypage.data.attributes.vacancies.length > 0
    }
  },
  components: {
    VacancyCard,
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#vacancies .content {
  background: none;
  margin-top: 20px;
  min-height: 470px;
  padding-bottom: 20px; }
  #vacancies .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #vacancies .content #vacancy-cards {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center; }
  #vacancies .content .vacancies {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    max-width: 80%; }
    @media screen and (max-width: 1024px) {
      #vacancies .content .vacancies {
        max-width: 100%; } }

</style>
