<template>
  <div id="contents">
    <h3 class="heading">Contents</h3>
    <div class="content-buttons">
      <button v-for="(item, key) in contents" :key="key" @click="scroll(item.idname)">{{item.item}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contents',
  props: { contents: Array },
  methods: {
    scroll (id) {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView(true)
      }
    }
  }
}
</script>

<style>
#contents {
  margin: 0;
  display: flex;
  position: fixed;
  margin-top: -25px;
  width: 400px;
  padding: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  z-index: 0;
}
#contents .heading {
  background: white;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
#contents .content-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  margin-top: -22.5px;
}
#contents button {
  width: 100%;
  padding: 10px;
  background: #330D73;
  color: white;
  border: none;
  border-bottom: 1px solid #6B3EB8;
  font-size: 20px;
  text-align: left;
}
#contents button:hover {
  cursor: pointer;
  background: #009645;
}
@media screen and (max-width: 1024px) {
  #contents {
    width: 100%;
  }
}
</style>
