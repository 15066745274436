<template>
    <div id="prep">
      <div v-if="hasData(preparatorypage)" class="content">
        <h2 class="page-title">{{preparatorypage.data.attributes.title}}</h2>
        <div class="link-cards">
          <LinkCard
            v-for="(linkcard, index) in preparatorypage.data.attributes.linkcards"
            :key="index"
            :linkcard="linkcard"
          />
        </div>
        <About class="page-about" :message="preparatorypage.data.attributes.about" />
        <Downloads :downloads="preparatorypage.data.attributes.downloads" />
      </div>
    </div>
  </template>
  
  <script>
  import About from '@/components/About'
  import LinkCard from '@/components/LinkCard'
  import Downloads from '@/components/Downloads'
  import { preparatorypage } from '../queries'
  
  export default {
    name: 'Prep',
    apollo: {
      preparatorypage
    },
    components: {
      About,
      LinkCard,
      Downloads
    },
    methods: {
      hasData (data) {
        if (data) {
          this.$store.dispatch('showLoader', false)
          return true
        } else {
          return false
        }
      }
    }
  }
  </script>
  
  <style>
  #prep .content {
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
  }
  #prep .content .page-title {
    color: white;
    padding: 10px;
    margin-top: 0px;
    background: #009645; }

  #prep .link-cards {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    justify-content: center;
  }

  #prep .content .downloads {
    position: relative;
    padding-bottom: 4em;
  }

  @media screen and (max-width: 1024px) {
      #prep .content {
        margin-top: 0;
      }
    }
  </style>
  