<template>
  <div id="intermediate-phase">
    <div v-if="hasData(intermediatepage)" class="content">
      <h2>{{intermediatepage.data.attributes.title}}</h2>
      <About :message="intermediatepage.data.attributes.about" />
      <div class="info">
        <InfoCard v-for="(info, key) in intermediatepage.data.attributes.information" :key="key" :info="info.info" />
      </div>
      <h3 class="hours-heading">School Hours</h3>
      <div class="hours">
        <TimeCard v-for="(grade, key) in intermediatepage.data.attributes.hours" :key="key" :title="grade.title" :times="grade.times" />
      </div>
      <Staff title="Meet our staff" :staff="intermediatepage.data.attributes.staff" :showEmail="false" />
      <Downloads :downloads="intermediatepage.data.attributes.downloads" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About' 
import InfoCard from '@/components/InfoCard'
import Staff from '@/components/Staff'
import TimeCard from '@/components/TimeCard'
import Downloads from '@/components/Downloads'
import { intermediatepage } from '../queries'
export default {
  name: 'IntermediatePhase',
  apollo: { intermediatepage },
  components: {
    About,
    Staff,
    TimeCard,
    Downloads,
    InfoCard
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#intermediate-phase .content {
  margin-top: 20px; }
  #intermediate-phase .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #intermediate-phase .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #intermediate-phase .content .hours-heading {
    padding: 5px;
    color: white;
    background: #009645; }
  #intermediate-phase .content .hours {
    display: flex;
    justify-content: center;
    flex-flow: wrap; }
</style>
