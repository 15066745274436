<template>
  <div v-if="member" class="staff-card fade-in-fwd">
    <img v-if="member.photo" :src="Url(member.photo.data.attributes.url)" alt="">
    <br><br>
    <div>{{member.names}}</div>
    <small><b>{{member.position}}</b></small><br><br>
    <a class="staff-email" v-if="showEmail" :href="`mailto:${member.email}`">{{member.email}}</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'StaffCard',
  props: {
    member: { type: Object },
    showEmail: Boolean
  },
  computed: {
    ...mapGetters([
      'Url'
    ])
  }
}
</script>

<style>
.staff-card {
  padding: 20px;
  margin: 10px;
  min-height: 360px;
  background: white;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.3);
  width: 220px; }
  .staff-card img {
    height: 250px; }
  .staff-card .staff-email {
    word-wrap: break-word;
  }
</style>
