<template>
  <div v-if="news" id="news">
    <NewsCard v-for="(item, key) in news" :key="key" :data="item" />
  </div>
</template>

<script>
import NewsCard from '@/components/NewsCard'
export default {
  name: 'News',
  props: {
    news: { Object }
  },
  components: {
    NewsCard
  }
}
</script>

<style>
#news {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }
</style>
