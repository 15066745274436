<template>
  <div class="calendar">
    <div class="content">
      <h2 class="page-title">Calendar</h2>
      <SmallLoader :text="'loading calendar..'" v-if="!loaded" />
      <iframe id="calendar-frame" src="https://calendar.google.com/calendar/embed?src=mitchellhouse.co.za_pokbja0keu75vvsc9ed953qqhk%40group.calendar.google.com&amp;ctz=Africa%2FJohannesburg" width="100%" height="800" frameborder="0" scrolling="no" style="border: 0px;"></iframe>
    </div>
  </div>
</template>

<script>
import SmallLoader from '../../components/SmallLoader'
export default {
  name: 'Calendar',
  data () {
    return {
      loaded: false
    }
  },
  mounted () {
    const calendar = document.getElementById('calendar-frame')
    calendar.onload = () => {
      this.loaded = true
    }
    this.$store.dispatch('showLoader', false)
  },
  components: {
    SmallLoader
  }
}
</script>

<style>
.calendar h2 {
  color: #330D73;
  background: white;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.3);
  padding: 10px; }

.calendar .content {
  display: flex;
  justify-content: center;
  flex-flow: wrap column; }
.calendar .content .page-title {
    color: white;
    padding: 10px;
    background: #009645; margin-bottom: 0px;}
.calendar #links {
  background: #330D73;
  height: 100px;
  display: flex;
  justify-content: center; }
  .calendar #links div {
    display: flex;
    justify-content: space-between;
    width: 50%; }
    .calendar #links div .icon {
      color: white;
      font-size: 4em; }
</style>
