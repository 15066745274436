<template>
  <div id="squirrel-nest">
    <div v-if="hasData(squirrelpage)" class="content">
      <h2>{{squirrelpage.data.attributes.title}}</h2>
      <About :message="squirrelpage.data.attributes.about" />
      <div class="info">
        <InfoCard v-for="(info, key) in squirrelpage.data.attributes.information" :key="key" :info="info.info" />
      </div>
      <h3 class="hours-heading">School Hours</h3>
      <div class="hours">
        <TimeCard v-for="(grade, key) in squirrelpage.data.attributes.hours" :key="key" :title="grade.title" :times="grade.times" />
      </div>
      <Staff title="Meet our staff" :staff="squirrelpage.data.attributes.staff" />
      <Downloads :downloads="squirrelpage.data.attributes.downloads" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About'
import InfoCard from '@/components/InfoCard'
import Staff from '@/components/Staff'
import TimeCard from '@/components/TimeCard'
import Downloads from '@/components/Downloads'
import { squirrelpage } from '../queries'
export default {
  name: 'squirrel',
  apollo: {
    squirrelpage
  },
  components: {
    About,
    InfoCard,
    Staff,
    Downloads,
    TimeCard
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#squirrel-nest .content {
  margin-top: 20px;
  color: #330D73; }
  #squirrel-nest .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #squirrel-nest .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #squirrel-nest .content .hours-heading {
    padding: 5px;
    color: white;
    background: #009645; }
  #squirrel-nest .content .hours {
    display: flex;
    justify-content: center;
    flex-flow: wrap; }
</style>
