import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/Admin'
import Admissions from '../views/Admissions'
import SquirrelNest from '../views/SquirrelNest'
import JuniorPhase from '../views/JuniorPhase'
import IntermediatePhase from '../views/IntermediatePhase'
import Information from '../views/Information'
import College from '../views/College'
import EnrichmentCentre from '../views/EnrichmentCentre'
import Sport from '../views/Sport'
import ParentPortal from '../views/ParentPortal'
import Publications from '../views/Publications'
import Vacancies from '../views/Vacancies'
import Boarding from '../views/Boarding'
import Contactus from '../views/Contactus'
import VirtualTour from '../views/VirtualTour'
import Calendar from '../views/Calendar'
import PrivacyPolicy from '../views/PrivacyPolicy'
import Prep from '../views/Prep'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin
    },
    {
      path: '/admission',
      name: 'admission',
      component: Admissions
    },
    {
      path: '/squirrel',
      name: 'squirrel',
      component: SquirrelNest
    },
    {
      path: '/junior',
      name: 'junior',
      component: JuniorPhase
    },
    {
      path: '/intermediate',
      name: 'intermediate',
      component: IntermediatePhase
    },
    {
      path: '/information',
      name: 'information',
      component: Information
    },
    {
      path: '/college',
      name: 'college',
      component: College
    },
    {
      path: '/enrichment',
      name: 'enrichment',
      component: EnrichmentCentre
    },
    {
      path: '/boarding',
      name: 'boarding',
      component: Boarding
    },
    {
      path: '/sport',
      name: 'sport',
      component: Sport
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contactus
    },
    {
      path: '/parent',
      name: 'parent',
      component: ParentPortal
    },
    {
      path: '/publications',
      name: 'publications',
      component: Publications
    },
    {
      path: '/virtualtour',
      name: 'virtualtour',
      component: VirtualTour
    },
    {
      path: '/vacancies',
      name: 'vacancies',
      component: Vacancies
    },
    {
      path: '/policy',
      name: 'policy',
      component: PrivacyPolicy
    },
    {
      path: '/prep',
      name: 'prep',
      component: Prep
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar
    }
  ]
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // Scroll to the top
  next();
})

export default router;