import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApollo from 'vue-apollo'
import ApolloClient from 'apollo-boost'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faUserSecret,
  faChevronLeft,
  faChevronRight,
  faFileDownload,
  faExternalLinkAlt,
  faXmark,
  faMapLocationDot,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faBars,
  faArrowUp
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: 'http://localhost:1338/graphql'
  // uri: 'https://mitchellhouse-1fc203e3c1c6.herokuapp.com/graphql'
  uri: 'https://api.mitchellhouse.co.za/graphql'
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

Vue.use(VueApollo)
library.add(
  faUserSecret,
  faChevronLeft,
  faChevronRight,
  faFileDownload,
  faExternalLinkAlt,
  faXmark,
  faFacebook,
  faTwitter,
  faInstagram,
  faMapMarkerAlt,
  faMapLocationDot,
  faPhone,
  faEnvelope,
  faBars,
  faArrowUp
)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
