<template>
  <div class="loader">
    <span class="icon">
      <span class="inner"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style>
  .loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin: 0 auto;
  padding: 0 auto;
  background: url(../../assets/bg.jpg) no-repeat;
  background-size: cover; }
  .loader .icon {
    width: 100px;
    height: 100px;
    border-radius: 25%; }
    .loader .icon .inner {
      animation: rotate 1.3s ease infinite;
      box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.3);
      background: #330D73;
      border-radius: 15px;
      width: 50px;
      height: 50px;
      margin-left: -25px;
      margin-top: 25px;
      position: absolute; }

</style>
