<template>
  <div class="staff">
    <h2>{{title}}</h2>
    <div class="staff-container">
      <StaffCard v-for="(member, key) in staff" :key="key" :member="member" :showEmail="showEmail" />
    </div>
  </div>
</template>

<script>
import StaffCard from '@/components/StaffCard'
export default {
  name: 'Staff',
  props: {
    title: String,
    staff: Array,
    showEmail: Boolean
  },
  components: {
    StaffCard
  }
}
</script>

<style>
.staff {
  background: linear-gradient(#fff, #e1e1e1);
  color: #330D73; }
  .staff h2 {
    background: #330D73 !important;
    color: white;
    padding: 10px; }
  .staff .staff-container {
    display: flex;
    flex-flow: wrap;
    justify-content: center; }
</style>
