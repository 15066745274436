<template>
  <a class="link-card" v-if="linkcard" :href="`/${linkcard.url}`">
    <div
      class="link-card-inner"
      :style="{
        background: linkcard.image.data
          ? `url(${this.Url(linkcard.image.data.attributes.url)})`
          : '#fff'
      }"
    >
      <h3>{{linkcard.title}}</h3>
    </div>
  </a>
</template>
  
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LinkCard',
  props: {
    linkcard: Object
  },
  computed: {
    ...mapGetters(['Url']),
  }
}
</script>
  
<style>
  .link-card {
    width: 300px;
    height: 150px;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    box-shadow: 5px 5px 15px rgba(42, 26, 83, 0.486);
    border-bottom: 5px solid #330D73;
  }

  .link-card:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }

  .link-card-inner {
    background-color: white;
    width: 300px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .link-card h3 {
    background: #320d73d2;
    padding: 10px;
    text-shadow: 1px 1px black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link-card img {
    width: 100%;
  }
</style>
  