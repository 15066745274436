<template>
  <div id="privacy-policy">
    <div v-if="hasData(privacypage)" class="content">
      <h2 class="page-title">{{privacypage.data.attributes.title}}</h2>
      <About :message="privacypage.data.attributes.about" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About'
import { privacypage } from '../queries'

export default {
  name: 'PrivacyPolicy',
  apollo: {
    privacypage
  },
  components: {
    About
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#privacy-policy .content {
  margin-top: 20px; }
  #privacy-policy .content .page-title {
    color: white;
    padding: 10px;
    background: #009645; }

@media screen and (max-width: 1024px) {
  #privacy-policy .content h2 {
    margin-top: 0;
  }
}
</style>
