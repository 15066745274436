<template>
  <div class="about" v-html="markdown(message)"></div>
</template>

<script>
import showdown from 'showdown'
const converter = new showdown.Converter()
export default {
  name: 'About',
  props: {
    message: { type: String }
  },
  methods: {
    markdown: text => converter.makeHtml(text)
  }
}
</script>

<style>
.about {
  height: auto;
  padding: 30px 50px;
  margin: 10px;
  background: white;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  border-radius: 8px;
  margin-left: 7.5%;
  max-width: 80%;
  text-align: justify;
  color: #330D73;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.2); }
  .about h3 {
    text-align: center;
    margin: 10px; }
  .about img {
    float: left;
    margin: 10px; }
  .about video {
    border: 5px solid #330D73;
    box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.5);
    margin: 10px; }
  .about li {
    display: block; }
  @media screen and (max-width: 1024px) {
    .about {
      flex-flow: wrap;
      padding: 30px;
      margin: 0 !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      width: 100%;
      margin-bottom: 10px;
    }
  }
</style>
