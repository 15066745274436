<template>
  <div id="footer">
    <div class="container">
      <div class="social">
        <a href="https://www.facebook.com/Mitchell-House-456661984367272/" target="_blank"><fa-icon class="icon" icon="fa-brands fa-facebook" /></a>
        <a href="https://www.twitter.com/MitchellHouseT" target="_blank">
          <svg class="icon twitter-icon" version="1.1" id="svg5" xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1668.56 1221.19"
            style="enable-background:new 0 0 1668.56 1221.19;" xml:space="preserve">
          <g id="layer1" transform="translate(52.390088,-25.058597)">
            <path id="path1009" d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
              h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"/>
          </g>
          </svg>
        </a>
        <a href="https://www.instagram.com/mitchell_house/" target="_blank"><fa-icon class="icon" icon="fa-brands fa-instagram" /></a>
      </div>
      <div class="links">
        <div>
          <router-link to="/">Home</router-link>
          <router-link to="/admin">Admin</router-link>
          <router-link to="/admission">Admissions</router-link>
          <router-link to="/prep">Prep School</router-link>
          <router-link to="/college">College</router-link>
          <router-link to="/enrichment">Enrichment Centre</router-link>
          <router-link to="/sport">Sport</router-link>
          <router-link to="/information">General Information</router-link>
        </div>
        <br>
        <div>
          <router-link to="/publications">Newsletters & Publications</router-link>
          <router-link to="/parent">Parent Portal</router-link>
          <router-link to="/virtualtour">Virtual Tour</router-link>
          <router-link to="/vacancies">Vacancies</router-link>
          <router-link to="/policy">Privacy Policy</router-link>
          <router-link to="/contact">Contact Us</router-link>
          <router-link to="/calendar">Calendar</router-link>
        </div>
      </div>
    </div>
    <div class="orgs">
      <a href="https://www.isasa.org" target="_blank"><img src="@/assets/isasa-logo.png" alt="Isasa"></a>
      <a href="https://www.ieb.co.za" target="_blank"><img src="@/assets/ieb-logo.png" alt="IEB"></a>
      <a href="https://www.ecoschools.global" target="_blank"><img src="@/assets/eco-schools-logo.png" alt="EcoSchools"></a>
      <a href="https://www.umalusi.org.za" target="_blank"><img src="@/assets/umalasi-logo.png" alt="Umalasi"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
#footer {
  border-top: 1px solid white;
  border-bottom: 20px solid #330D73;
  background: linear-gradient(#fff, #d1d1ff);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column;
  position: relative;
  z-index: 2;
  padding-bottom: 10px;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.3); }
  #footer .icon {
    font-size: 40px;
    color: #330D73; }
  #footer .orgs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap; }
    #footer .orgs img {
      margin: 10px 50px;
      max-height: 50px;
      max-width: 100px; }
      #footer .orgs img:hover {
        transition: 0.3s;
        transform: scale(1.1); }
  #footer .container {
    display: flex;
    flex-flow: wrap column;
    justify-content: center;
    align-items: center; }
    #footer .social {
      display: flex;
      flex-flow: row;
    }
    #footer .container .social a {
      text-decoration: none; }
    #footer .container .social .icon {
      width: 40px;
      margin: 20px; }
      #footer .container .social .twitter-icon {
        width: 55px;
        fill: #330D73;
      }
      #footer .container .social .twitter-icon:hover {
        width: 55px;
        fill: #009645;
      }
      #footer .container .social .icon:hover {
        color: #009645; transform: scale(1.1); transition: all 0.3s;}
    #footer .container .links div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap row; }
      #footer .container .links div a {
        margin: 15px;
        text-decoration: none; }
        #footer .container .links div a:hover {
          color: #009645; }

</style>
