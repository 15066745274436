<template>
  <div id="navbar">
    <button @click="showMenu" id="mobile-btn" v-if="mobile"><fa-icon class="icon" icon="fas fa-bars" /></button>
    <div v-if="menu" @click="hideMenu" class="links">
      <router-link to="/">Home</router-link>
      <router-link to="/admin">Admin</router-link>
      <router-link to="/admission">Admissions</router-link>
      <router-link to="/prep">Prep School</router-link>
      <router-link to="/college">College</router-link>
      <router-link to="/enrichment">Enrichment Centre</router-link>
      <router-link to="/sport">Sport</router-link>
      <router-link to="/information">General Information</router-link>
      <router-link to="/publications">Newsletters & Publications</router-link>
      <router-link to="/parent">Parent Portal</router-link>
      <router-link to="/virtualtour">Virtual Tour</router-link>
      <router-link to="/vacancies">Vacancies</router-link>
      <router-link to="/policy">Privacy Policy</router-link>
      <router-link to="/contact">Contact Us</router-link>
      <router-link to="/calendar">Calendar</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      mobile: false,
      menu: true
    }
  },
  mounted () {
    try {
      window.addEventListener('resize', this.responsive)
      this.responsive()
    } catch (error) {
      
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.responsive)
  },
  methods: {
    responsive () {
      if (window.innerWidth < 1024) {
        this.mobile = true
        this.menu = false
      }
      else {
        this.mobile = false
        this.menu = true
      }
    },
    showMenu () {
      this.menu = !this.menu
    },
    hideMenu () {
      if (this.mobile)
        this.menu = false
    }
  }
}
</script>

<style>
#navbar {
  display: block;
  height: auto;
  z-index: 9999;
  margin: 0;
  margin-bottom: -20px;
}
#navbar #mobile-btn {
  background: none;
  color: white;
  border: none;
  font-size: 30px;
  width: 50px;
}

#navbar #mobile-btn:focus {
  background: #009645;
}
#navbar .links {
  background: #330D73;
  padding: 5px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}
#navbar .links a {
  background: none;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  transition: 0.2s;
  margin-left: 5px;
}
#navbar .links a:hover {
  cursor: pointer;
  background: #009645;
  transition: none;
}
@media screen and (max-width: 1024px) {
  #navbar {
    background: #330D73;
    display: flex;
    height: auto;
  }
  #navbar .links {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: left;
    position: absolute;
    width: 50%;
    padding-left: 50px;
    transition: 0.3s all;
    z-index: 99;
    animation: fadeIn 0.3s;
  }

  #navbar a {
    height: auto;
  }

  #navbar #mobile-btn {
    z-index: 100;
    padding: 5px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
