<template>
  <div :style="newStyle" class="loading">
    <div class="small-loader"></div>
    <b class="loading-text">{{text}}</b>
  </div>
</template>

<script>
export default {
  name: 'SmallLoader',
  props: {
    newStyle: { Object },
    text: { type: String }
  }
}
</script>

<style>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #330D73 !important;
  background: white;
  padding: 20px;
  box-shadow: 0px, 0px, 15px, rgba(0, 0, 100, 0.2); }
  .loading b {
    color: #330D73 !important; }
  .loading .small-loader {
    background: #330D73;
    width: 25px;
    height: 25px;
    margin: 10px;
    border-radius: 5px;
    animation: rotate 1.5s ease-in-out infinite; }
</style>