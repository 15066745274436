import { gql } from "apollo-boost"

export const squirrelpage = gql`query {
  squirrelpage {
    data {
      attributes {
        title
        about
        information { title, info }
        hours { title times { day time } }
        downloads { title downloads { title file { data { attributes { url }}}}}
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const sportpage = gql`query {
  sportpage {
    data {
      attributes {
        title
        about
        hours { title times { day time } }
        sports { title term { term sport { sport }}}
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const publicationpage = gql`query {
  publicationpage {
    data {
      attributes {
        title
        publications { title thumbnail { data { attributes { url } } } url }
        secondtitle
        downloads { title downloads { title file { data { attributes { url }}}}}
      }
    }
  }
}`

export const privacypage = gql`query {
  privacypage {
    data {
      attributes {
        title
        about
      }
    }
  }
}`

export const preparatorypage = gql`query {
  preparatorypage {
    data {
      attributes {
        title
        linkcards { title, url, image { data {attributes {url}}} }
        about
        downloads { title downloads { title file { data { attributes { url }}}}}
      }
    }
  }
}`

export const partnerpage = gql`query {
  partnerpage {
    data {
      attributes {
        title
        about
        information { title, info }
      }
    }
  }
}`

export const parentassociationpage = gql`query {
  parentassociationpage {
    data {
      attributes {
        title
        about
        information { title, info }
      }
    }
  }
}`

export const parentpage = gql`query {
  parentpage {
    data {
      attributes {
        title
        about
        studentportal
        parentportal
      }
    }
  }
}`

export const opendaypage = gql`query {
  opendaypage {
    data {
      attributes {
        title
        about
        important
        videos { title url thumbnail { data { attributes { url }}} }
      }
    }
  }
}`

export const juniorpage = gql`query {
  juniorpage {
    data {
      attributes {
        title
        about
        information { title, info }
        downloads { title downloads { title file { data { attributes { url }}}}}
        hours { title times { day time } }
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const intermediatepage = gql`query {
  intermediatepage {
    data {
      attributes {
        title
        about
        information { title, info }
        downloads { title downloads { title file { data { attributes { url }}}}}
        hours { title times { day time } }
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const informationpage = gql`query {
  informationpage {
    data {
      attributes {
        title
        about
        downloads { title downloads { title file { data { attributes { url }}}}}
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const homepage = gql`query {
  homepage {
    data {
      attributes {
        title
        welcomevideo
        news { title url thumbnail { data { attributes { url }}} description }
        gallery { data { attributes { url }} }
      }
    }
  }
}`

export const enrichmentpage = gql`query {
  enrichmentpage {
    data {
      attributes {
        title
        about
        history
        getinvolved
        information { title, info }
        downloads { title downloads { title file { data { attributes { url }}}}}
        hours { title times { day time } }
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const collegepage = gql`query {
  collegepage {
    data {
      attributes {
        title
        linkcards { title, url, image { data {attributes {url}}} }
        about
        information { title, info }
        downloads { title downloads { title file { data { attributes { url }}}}}
        hours { title times { day time } }
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const boardingpage = gql`query {
  boardingpage {
    data {
      attributes {
        title
        about
        downloads { title downloads { title file { data { attributes { url }}}}}
      }
    }
  }
}`

export const admin = gql`query {
  admin {
    data {
      attributes {
        title
        about
        staff (pagination: { start: 0, limit: 100 }) {
          names
          position
          email
          photo { data { attributes { url } }}}
      }
    }
  }
}`

export const admissionpage = gql`query {
  admissionpage {
    data {
      attributes {
        title
        contents (pagination: { start: 0, limit: 100 }) { item idname }
        introduction
        values
        approach
        process
        downloads { title downloads { title file { data { attributes { url }}}}}
      }
    }
  }
}`

export const vacancypage = gql`query {
  vacancypage {
    data {
      attributes {
        title
        vacancies (pagination: { start: 0, limit: 100 }) { position description expiry advert { data { attributes { url }}} specifications { data { attributes { url }}} }
      }
    }
  }
}`