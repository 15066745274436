<template>
  <div id="parent-portal">
    <div v-if="hasData(parentpage)" class="content">
      <h2>{{parentpage.data.attributes.title}}</h2>
      <div style="background: white">
        <button
          class="toggle-button"
          @click="goToPortal(parentpage.data.attributes.parentportal)">Go to Parent Portal
        </button>
        <button
          class="toggle-button"
          @click="goToPortal(parentpage.data.attributes.studentportal)">Go to Student Portal
        </button>
      </div>
      <About :message="parentpage.data.attributes.about" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About'
import { parentpage } from '../queries'

export default {
  name: 'ParentPortal',
  apollo: { parentpage },
  methods: {
    goToPortal (portal) {
      window.open(portal, '__blank')
    },
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  },
  components: {
    About
  }
}
</script>

<style>
#parent-portal .content {
  margin-top: 20px; }
  #parent-portal .content .student, #parent-portal .content .parent {
    margin-top: -40px; }
  #parent-portal .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #parent-portal .content .portal {
    width: 100%;
    height: 100%;
    height: 600px;
    background: white; }
  #parent-portal .content .toggle-button {
    background: linear-gradient(#330D73, #6B3EB8);
    color: white;
    padding: 20px;
    margin: 10px;
    position: relative;
    z-index: 10; }
    #parent-portal .content .toggle-button:hover {
      background: linear-gradient(#009645, #39C479);
      transition: 0.3s; }
@media screen and (max-width: 1024px) {
  #parent-portal .content h2 {
    margin-top: 0;
  }
}
</style>
