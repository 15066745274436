<template>
  <div id="boarding">
    <div v-if="hasData(boardingpage)" class="content">
      <h2>{{boardingpage.data.attributes.title}}</h2>
      <About :message="boardingpage.data.attributes.about" />
      <Downloads :downloads="boardingpage.data.attributes.downloads" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About'
import Downloads from '@/components/Downloads'
import { boardingpage } from '../queries'
export default {
  name: 'Boarding',
  apollo: { boardingpage },
  components: {
    About,
    Downloads
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#boarding .content {
  margin-top: 20px;
  color: #330D73; }
  #boarding .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #boarding .content h3 {
    text-align: center; }

#boarding .banner {
  overflow: hidden; }
  #boarding .banner img {
    box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2);
    width: 100%; }
</style>
