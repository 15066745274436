<template>
  <div class="sport-card">
    <h3 class="card-title">{{sports.title}}</h3>
    <div class="inner">
      <div class="terms" v-for="(sport, key) in sports.term" :key="key">
        <h4 class="term-heading">{{sport.term}}</h4>
        <div class="term-sports">
          <div class="sport" v-for="(s, key) in sport.sport" :key="key">{{s.sport}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SportCard',
  props: {
    sports: { type: Object }
  }
}
</script>

<style>
.sport-card {
  background: #fff;
  color: #330D73;
  margin: 10px;
  min-width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2); }
  .sport-card .card-title {
    padding-bottom: 10px; }
  .sport-card .inner {
    display: flex;
    flex-flow: wrap;
    margin-top: -20px;
    line-height: 24px; }
  .sport-card .terms {
    width: 150px;
    padding: 10px;
    border-right: 1px solid #e1e1e1; }
    .sport-card .terms:last-child {
      border-right: none; }
  .sport-card .term-heading {
    color: #39C479;
    padding-bottom: 5px; }

@media screen and (max-width: 1024px) {
  .sport-card {
    width: auto;
  }

  .sport-card .terms {
    border: none;
    border-bottom: 1px solid #e1e1e1;
  }
}
</style>
