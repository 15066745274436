<template>
  <div id="logo">
    <router-link  class="logo" to="/">
      <img src="@/assets/logo.png" alt="">
      <div class="title">Mitchell House</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style>
#logo .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 10px;
  text-decoration: none;
  width: auto;
}
#logo img {
  height: 100px !important; }

#logo .title {
  font-size: 40px;
  margin-left: 20px;
  color: #330D73; }
</style>
