<template>
  <div class="news-card">
    <a :href="data.url.includes('http') ? data.url : Url(data.url)" target="_blank">
      <img v-if="data.thumbnail" :src="Url(data.thumbnail.data.attributes.url)" alt="">
      <h3 v-if="data.title" class="heading">{{data.title}}</h3>
      <div v-if="data.description" class="description">{{data.description}}</div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NewsCard',
  props: {
    data: { Object }
  },
  computed: {
    ...mapGetters([
      'Url'
    ])
  }
}
</script>

<style>
.news-card {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  width: 300px;
  height: auto;
  min-height: 450px;
  max-height: 600px;
  background: white;
  border-radius: 10px;
  transition: 0.2s;
  margin: 10px;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.3); }
  .news-card a {
    text-decoration: none !important;
    color: #330D73; }
  .news-card .heading {
    margin-top: 5px; }
  .news-card .description {
    padding: 10px; }
  .news-card img {
    height: 300px;
    max-height: 300px; }
  .news-card:hover {
    transform: scale(1.02);
    transition: 0.2s; }
</style>
