<template>
  <div id="admin">
    <div v-if="hasData(admin)" class="content">
      <h2>{{admin.data.attributes.title}}</h2>
      <About v-if="admin.data.attributes.about" :message="admin.data.attributes.about" />
      <Staff :title="'Meet our staff'" :staff="admin.data.attributes.staff" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About'
import Staff from '@/components/Staff'
import { admin } from '../queries'

export default {
  name: 'Sport',
  apollo: { admin },
  components: {
    About,
    Staff
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#admin #support-links {
  background: linear-gradient(#00cc66, #009645);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center; }
#admin #up-button {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #330D73;
  color: white;
  border: none;
}
#admin .content {
  margin-top: 20px;
  color: #330D73; }
  #admin .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #admin .content h3 {
    text-align: center; font-size: 24px; font-weight: 600; }
  #admin .about strong {
    font-size: 20px !important;
  }
  #admin a {
    color: #009645;
  }
  #admin .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #admin .content .headmaster-message {
    height: auto;
    padding: 20px;
    margin: 10px;
    background: white;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-left: 10%;
    max-width: 80%;
    box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 1024px) {
      #admin .content {
        margin-top: 0;
      }
      #admin .content .headmaster-message {
        flex-flow: wrap;
        max-width: 100%;
        margin: 0;
        margin-bottom: 10px; } }
    #admin .content .headmaster-message .headmaster-img {
      width: 300px; }
      @media screen and (max-width: 1024px) {
        #admin .content .headmaster-message .headmaster-img {
          width: 200px; } }
    #admin .content .headmaster-message .message {
      text-align: justify;
      padding: 10px; }
</style>
