<template>
  <div class="gallery">
    <div v-if="this.gallery" class="container">
      <button
        class="next-button"
        @click="setImage(-1)"
      >
        <fa-icon class="icon" icon="fas fa-chevron-left" />
      </button>
      <div id="image-box">
        <img id="current-image" src="" alt="">
        <SmallLoader :newStyle="{ background: 'none', boxShadow: 'none', height: '100%' }" v-if="loading" />
      </div>
      <button
        class="next-button"
        @click="setImage(1)"
      >
        <fa-icon class="icon" icon="fas fa-chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SmallLoader from '@/components/SmallLoader'
export default {
  name: 'Gallery2',
  props: {
    gallery: Object
  },
  data () {
    return {
      loading: true,
      currentImage: 0,
      automater: null
    }
  },
  computed: {
    ...mapGetters([
      'Url'
    ]),
    imageCount () {
      return this.gallery ? this.gallery.data.length : 0
    }
  },
  mounted () {
    this.setImage(0)
    this.resetAutoMater()
  },
  methods: {
    setImage (value) {
      this.resetAutoMater()
      if (this.gallery) {
        try {
          this.loading = true
          const image = document.getElementById('current-image')
          this.currentImage += value
          if (this.currentImage < 0) this.currentImage = this.imageCount - 1
          else if (this.currentImage >= this.imageCount) this.currentImage = 0
          const imageUrl = this.gallery.data[this.currentImage].attributes.url
          image.src = this.Url(imageUrl)
          image.onload = () => {
            this.loading = false
          }
        } catch (error) {
          clearInterval(this.automater)
        }
      } else {
        clearInterval(this.automater)
      }
    },
    resetAutoMater () {
      clearInterval(this.automater)
      this.automater = setInterval(() => this.setImage(1), 8000)
    }
  },
  components: {
    SmallLoader
  }
}
</script>

<style>
.gallery {
  height: 500px;
  width: 100%;
}
  @media screen and (max-width: 1300px) {
    .gallery {
      width: 100%;
      margin: 0; } }
  .gallery .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
  .gallery #image-box {
    width: 100%;
    height: 100%;
    background: #ffffff77;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px; }
    @media screen and (max-width: 1024px) {
      .gallery #image-box {
        width: 768px; } }
    @media screen and (max-width: 768px) {
      .gallery #image-box {
        width: 100%; } }
    @media screen and (max-width: 480px) {
      .gallery #image-box {
        width: 100%; height: 100%;} }
    .gallery #image-box .image-loader {
      width: 80px;
      height: 80px;
      background: #009645; }
    .gallery #image-box img {
      height: 100%; }
  .gallery button {
    width: 50px;
    background: none;
    border: none; }
    .gallery button:hover {
      cursor: pointer;}
    .gallery button .icon {
      font-size: 40px;
      color: white;
      text-shadow: 0px 0px 40px #330D73; }
      .gallery button .icon:hover {
        transform: scale(1.1);
        transition: 0.2s; }
</style>
