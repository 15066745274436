<template>
  <a :href="video.url" target="_blank" class="video-card">
    <img class="thumbnail" v-if="video.thumbnail" :src="Url(video.thumbnail.data.attributes.url)" alt="">
    <img class="play-icon" src="@/assets/play-icon.svg" alt="" srcset="">
    <h3 class="heading">{{video.title}}</h3>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VideoCard',
  props: {
    video: Object
  },
  computed: {
    ...mapGetters([
      'Url'
    ])
  }
}
</script>

<style>
.video-card {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 200px;
  height: auto;
  height: 250px;
  background: white;
  border-radius: 5px;
  transition: 0.2s;
  margin: 10px;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.3);
  text-decoration: none !important;
  color: #330D73; }
  .video-card h3 {
    margin-top: 5px;
    font-size: 16px;
    height: 60px;
    background: #330D73;
    color: white;
    font-weight: 100;
    padding: 5px; }
  .video-card .thumbnail {
    height: 190px;
    max-height: 200px; }
  .video-card .play-icon {
    position: relative;
    margin-top: -150px;
    height: 50px; }
  .video-card:hover {
    transform: scale(1.01);
    transition: 0.2s; }
  .video-card:hover > .play-icon {
    transform: scale(1.2);
    transition: 0.2s; }

</style>
