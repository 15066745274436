<template>
  <div id="app">
    <div class="bg"></div>
    <Logo />
    <Navbar />
    <div id="wrapper">
      <Loader v-if="this.$store.getters.showLoader" />
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<script>

import Loader from './components/Loader'
import Logo from './components/Logo/logo.vue'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
export default {
  name: 'App',
  components: {
    Loader,
    Logo,
    Navbar,
    Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

html, body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  /* font-family: 'Varela Round', sans-serif; */
  scroll-behavior: smooth;
}
  
body {
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 0;
  flex-direction: column;
}
#wrapper {
  flex: 1;
  display: flex;
  width: auto;
  flex-direction: column;
  padding: 0;
  margin: 0;
}


@media screen and (max-width: 1024px) {
  .content {
    justify-content: center;
    align-items: center;
    display: flex;
    width: auto;
    flex-flow: column;
  }
  .content h2 {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#app button:focus {
  outline: 0;
}
#app .bg {
  background: url(./assets/bg.jpg) no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
