<template>
  <div id="info-box">
    <div class="info-box-link" @click="toggle">
      {{title}}<fa-icon class="icon" icon="fa-solid fa-external-link-alt" />
    </div>
    <div v-if="show" class="box fade-in">
      <div class="infos">
        <button class="close-button" @click="toggle"><fa-icon class="icon" icon="fa-solid fa-xmark" /></button>
        <div v-html="markdown(info)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import showdown from 'showdown'
const converter = new showdown.Converter()
export default {
  name: 'InfoBox',
  props: {
    info: { type: String },
    title: { type: String }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
      console.log(this.info)
    },
    markdown: text => converter.makeHtml(text)
  }
}
</script>

<style>
#info-box {
  margin-bottom: 5px; }
  #info-box .info-box-link {
    background: white;
    padding: 10px;
    color: #330D73;
    display: flex;
    justify-content: space-between;
    width: 300px; }
    #info-box .info-box-link:hover {
      background: #330D73;
      color: white;
      cursor: pointer; }
    #info-box .info-box-link .icon {
      color: #330D73; }
  #info-box .infos {
    max-width: 700px;
    background: white !important;
    padding: 20px;
    box-shadow: 0px, 0px, 10px, rgba(0, 0, 100, 0.5);
    border-radius: 10px;
    overflow: auto; }
    @media screen and (max-width: 768px) {
      #info-box .infos {
        width: 100%;
        border-radius: 0px; } }
  #info-box .box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), #009645);
    border-bottom: 10px solid #009645;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    color: #330D73;
    text-align: left; }
  #info-box li {
    line-height: 24px;
    display: block;
    text-align: left; }
  #info-box h1, #info-box h2, #info-box h3, #info-box h4, #info-box h5 {
    text-align: center;
    margin: 10px;
    background: none !important;
    color: #330D73 !important; }
  #info-box .close-button {
    background: #FF3131;
    color: white;
    height: 35px;
    width: 35px;
    border: none;
    border-radius: 5px;
    float: right !important; }
    #info-box .close-button:hover {
      background: #330D73; }
    #info-box .close-button .icon {
      color: white; }
</style>
