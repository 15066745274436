<template>
  <div id="information">
    <div v-if="hasData(informationpage)" class="content">
      <h2>{{informationpage.data.attributes.title}}</h2>
      <About :message="informationpage.data.attributes.about" />
      <Downloads :downloads="informationpage.data.attributes.downloads" />
    </div>
  </div>
</template>

<script>
import About from '@/components/About'
import Downloads from '@/components/Downloads'
import { informationpage } from '../queries'

export default {
  name: 'Information',
  apollo: { informationpage },
  components: {
    About,
    Downloads
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
#information #support-links {
  background: linear-gradient(#00cc66, #009645);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center; }

#information .content {
  margin-top: 20px;
  color: #330D73; }
  #information .content h2 {
    color: white;
    padding: 10px;
    background: #009645; }
  #information .content h3 {
    text-align: center; }
  #information .content .info {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    opacity: 0.9;
    background: linear-gradient(#6B3EB8, #330D73);
    padding: 10px; }
  #information .content .headmaster-message {
    height: auto;
    padding: 20px;
    margin: 10px;
    background: white;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-left: 10%;
    max-width: 80%;
    box-shadow: 0px, 0px, 20px, rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 1024px) {
      #information .content h2 {
        margin-top: 0;
      }
      #information .content .headmaster-message {
        flex-flow: wrap;
        max-width: 100%;
        margin: 0;
        margin-bottom: 10px; } }
    #information .content .headmaster-message .headmaster-img {
      width: 300px; }
      @media screen and (max-width: 1024px) {
        #information .content .headmaster-message .headmaster-img {
          width: 200px; } }
    #information .content .headmaster-message .message {
      text-align: justify;
      padding: 10px; }
</style>
