<template>
  <div class="time-card">
    <h3 class="title">{{title}}</h3>
    <div class="content" v-for="(time, key) in times" :key="key">
      <div class="day"><b>{{time.day}}</b></div>
      <div class="time">{{time.time}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeCard',
  props: {
    title: String,
    times: Array
  }
}
</script>

<style>
.time-card {
  background: white;
  padding: 20px;
  color: #330D73;
  margin: 10px;
  width: 300px;
  box-shadow: 0px, 0px, 10px, rgba(0, 0, 100, 0.2); }
  .time-card .content {
    border-bottom: 1px solid #d1d1d1;
    padding: 0px 0px 15px 0px;
    display: flex;
    justify-content: space-between; }
    .time-card .content .day {
      color: #39C479;
      text-align: left; }
    .time-card .content .time {
      text-align: right; }
    .time-card .content:last-child {
      border-bottom: none; }
</style>
