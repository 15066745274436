<template>
  <div class="home">
    <div v-if="hasData(homepage)" id="content">
      <div v-if="homepage.data.attributes.news">
        <h2>Latest News</h2>
        <br>
        <News :news="homepage.data.attributes.news" />
      </div>
      <div v-if="homepage.data.attributes.gallery">
        <br>
        <h2>Gallery</h2>
        <br>
        <div class="gallery">
          <Gallery2 :gallery="homepage.data.attributes.gallery" />
          <div id="twitter"></div>
        </div>
      </div>
      <br>
      <h2 style="margin-bottom: -20px;">Welcome</h2>
      <iframe
        class="fade-in-fwd"
        id="youtube-video"
        :src="homepage.data.attributes.welcomevideo"
        frameborder="0"
        style="margin-bottom: 10px;"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
      <br>
    </div>
  </div>
</template>

<script>
import News from '@/components/News'
import Gallery2 from '@/components/Gallery2'
import { homepage } from '../views/queries'
import { hasData } from '../components/helper'

export default {
  name: 'home',
  apollo: { homepage },
  components: {
    News,
    Gallery2
  },
  methods: {
    hasData (data) {
      if (data) {
        this.$store.dispatch('showLoader', false)
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
.home #youtube-video {
  margin-top: 40px;
  width: 1000px;
  height: 500px; }
  @media screen and (max-width: 1024px) {
    .home #youtube-video {
      width: 700px; } }
  @media screen and (max-width: 768px) {
    .home #youtube-video {
      width: 480px; } }
  @media screen and (max-width: 480px) {
    .home #youtube-video {
      width: 300px; } }

.home h2 {
  color: #330D73;
  background: white;
  box-shadow: 0px, 0px, 20px, rgba(0, 0, 100, 0.3);
  padding: 10px; }

.home .content {
  display: flex;
  justify-content: center;
  flex-flow: wrap column; }

.home #links {
  background: #330D73;
  height: 100px;
  display: flex;
  justify-content: center; }
  .home #links div {
    display: flex;
    justify-content: space-between;
    width: 50%; }
    .home #links div .icon {
      color: white;
      font-size: 4em; }

</style>
