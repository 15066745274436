function sortTimes(hours) {
  const times = {}
  for (let hour in hours) {
    const {
      activity
    } = hours[hour]
    times[activity] = []
  }
  for (let hour in hours) {
    const {
      activity,
      day,
      time
    } = hours[hour]
    times[activity].push({
      day,
      time
    })
  }
  return times
}
  
module.exports = {
  sortTimes
}